import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { getCurrentMemberAccount } from '@wix/ambassador-loyalty-v1-account/http';
import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { getCurrentMemberCoupons, queryCouponTemplate } from '@wix/ambassador-loyalty-v1-coupon/http';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';
import { ControllerFlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import { getTiersProgram } from '@wix/ambassador-loyalty-v1-tier/http';

import { Experiments } from '../../constants';
import { TiersProgram } from '../../types/domain';

export const loadData = async ({ experiments, httpClient }: ControllerFlowAPI) => {
  const tiersEnabled = experiments.enabled(Experiments.ShowTiers);

  const getLoyaltyProgramPromise = httpClient.request(getLoyaltyProgram({}));
  const getTiersProgramPromise = tiersEnabled ? httpClient.request(getTiersProgram({})) : Promise.resolve();
  const getCurrentMemberCouponsPromise = httpClient.request(getCurrentMemberCoupons({}));

  const getCurrentMemberAccountPromise = httpClient.request(getCurrentMemberAccount({})).catch((e) => {
    if (e instanceof Error && e.message.includes('404')) {
      return;
    }

    throw e;
  });

  const rewardsAndCouponsPromise = httpClient.request(listRewards({})).then(async ({ data }) => {
    const rewards = data.rewards ?? [];
    let templateCoupons: CouponReference[] = [];
    let templateCouponsError;

    if (rewards.length) {
      try {
        templateCoupons = await getTemplateCoupons(httpClient, rewards);
      } catch (e) {
        templateCouponsError = e;
      }
    }

    return { rewards, templateCoupons, templateCouponsError };
  });

  const [
    loyaltyProgramResponse,
    tiersProgramResponse,
    currentMemberCouponsResponse,
    currentMemberAccountResponse,
    { rewards, templateCoupons, templateCouponsError },
  ] = await Promise.all([
    getLoyaltyProgramPromise,
    getTiersProgramPromise,
    getCurrentMemberCouponsPromise,
    getCurrentMemberAccountPromise,
    rewardsAndCouponsPromise,
  ]);

  // When user has no points loyalty account doesn't exist. In that case we ignore template coupons error.
  if (currentMemberAccountResponse && templateCouponsError) {
    throw templateCouponsError;
  }

  const loyaltyProgram = loyaltyProgramResponse.data.loyaltyProgram ?? {};
  const account = currentMemberAccountResponse?.data.account ?? {
    points: {
      balance: 0,
      redeemed: 0,
      earned: 0,
      adjusted: 0,
    },
    tier: {
      points: 0,
    },
  };
  const claimedCoupons =
    currentMemberCouponsResponse.data?.loyaltyCoupons?.filter((coupon) => !coupon.couponReference?.deleted) ?? [];

  const tiersProgram: TiersProgram = {
    programSettings: tiersProgramResponse?.data.programSettings ?? undefined,
    tiers: tiersProgramResponse?.data.tiers ?? undefined,
  };

  return {
    loyaltyProgram,
    account,
    rewards,
    templateCoupons,
    claimedCoupons,
    tiersProgram,
  };
};

async function getTemplateCoupons(httpClient: IHttpClient, rewards: Reward[]) {
  let templateCoupons: CouponReference[] = [];
  const templateIds = rewards
    .filter((reward) => !!reward.coupon?.templateId)
    .map((reward) => reward.coupon?.templateId);

  if (templateIds.length) {
    const { data } = await httpClient.request(
      queryCouponTemplate({
        query: {
          filter: JSON.stringify({ id: { $in: templateIds } }),
        },
      }),
    );

    if (data.couponReferences) {
      templateCoupons = data.couponReferences;
    }
  }

  return templateCoupons;
}
