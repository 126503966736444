import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getCouponSubtitle } from '@wix/loyalty-coupon-names';

const formatMoneyAmount = (amount: number, locale: string, currency: string) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount);

export const createRewardDescriptionBuilder =
  (loyaltyProgram: LoyaltyProgram, { translations }: ControllerFlowAPI, locale: string, currency: string) =>
  (reward: Reward, configIndex: number, coupon?: CouponReference) => {
    let result: string;
    const pointsName = loyaltyProgram.pointDefinition?.customName;
    const { t } = translations;

    if (reward.type === RewardType.DISCOUNT_AMOUNT) {
      const { costInPoints, amount } = reward.discountAmount?.configsByTier?.[configIndex]!;
      const key = pointsName
        ? 'app.reward-description.discount-amount-custom'
        : 'app.reward-description.discount-amount';

      result = t(key, {
        pointsName,
        pointsRequired: costInPoints,
        discountAmount: formatMoneyAmount(parseFloat(amount ?? '0'), locale, currency),
      });
    } else if (reward.type === RewardType.COUPON) {
      const { requiredPoints } = reward.coupon?.configs?.[configIndex]!;
      const discountLabel = getCouponSubtitle({
        coupon: coupon!,
        locale,
        currency,
        t,
        referenceCoupon: true,
      });
      const pointsKey = pointsName ? 'app.reward-description.coupon-any-custom' : 'app.reward-description.coupon-any';
      const pointsLabel = t(pointsKey, {
        pointsName,
        pointsRequired: requiredPoints,
      });

      result = `${pointsLabel} ${discountLabel}`;
    } else if (reward.type === RewardType.COUPON_REWARD) {
      const couponReward = reward.couponReward!;
      const configsByTier =
        couponReward.percentage?.configsByTier ??
        couponReward.fixedAmount?.configsByTier ??
        couponReward.freeShipping?.configsByTier;

      const config = configsByTier?.[configIndex]!;
      const discountLabel = getCouponSubtitle({ locale, currency, t, couponReward, config });
      const pointsKey = pointsName ? 'app.reward-description.coupon-any-custom' : 'app.reward-description.coupon-any';
      const pointsLabel = t(pointsKey, {
        pointsName,
        pointsRequired: config.costInPoints,
      });

      result = `${pointsLabel} ${discountLabel}`;
    } else {
      result = t('app.reward-description.empty');
    }

    return result;
  };
