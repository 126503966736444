import { configureStore, bindActionCreators } from '@reduxjs/toolkit';
import { Extra } from '../../../../types/store';
import { rootReducer, RootState } from './rootReducer';
import {
  accountSlice,
  accountThunk,
  couponsSlice,
  couponsThunk,
  rewardsSlice,
  rewardsThunk,
  navigationThunk,
  toastSlice,
  toastThunk,
} from './slices';

export const createStore = (extraArgument: Extra, initialState: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          isSerializable: () => true,
        },
        thunk: { extraArgument },
      }),
  });

export type Store = ReturnType<typeof createStore>;

export const getActionHandlers = (store: Store) => ({
  accountHandlers: bindActionCreators({ ...accountSlice.actions, ...accountThunk }, store.dispatch),
  couponsHandlers: bindActionCreators({ ...couponsSlice.actions, ...couponsThunk }, store.dispatch),
  rewardsHandlers: bindActionCreators({ ...rewardsSlice.actions, ...rewardsThunk }, store.dispatch),
  navigationHandlers: bindActionCreators(navigationThunk, store.dispatch),
  toastHandlers: bindActionCreators({ ...toastSlice.actions, ...toastThunk }, store.dispatch),
});
