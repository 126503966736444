import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { isAfter } from 'date-fns';

import { SimpleReward } from '../../types/domain';
import { isRewardAvailable } from '../is-reward-available';

export const compareRewardsFn = (first: SimpleReward, second: SimpleReward, pointsBalance: number | undefined) => {
  // Sort by availability
  if (
    isRewardAvailable({ requiredPoints: first.requiredPoints, pointsBalance }) === true &&
    isRewardAvailable({ requiredPoints: second.requiredPoints, pointsBalance }) === false
  ) {
    return -1;
  }

  if (
    isRewardAvailable({ requiredPoints: first.requiredPoints, pointsBalance }) === false &&
    isRewardAvailable({ requiredPoints: second.requiredPoints, pointsBalance }) === true
  ) {
    return 1;
  }

  // Then sort by activation
  if (first.couponActivated && !second.couponActivated) {
    return -1;
  }

  if (!first.couponActivated && second.couponActivated) {
    return 1;
  }

  // Then sort by type
  if (
    [RewardType.COUPON, RewardType.COUPON_REWARD].includes(first.type!) &&
    second.type === RewardType.DISCOUNT_AMOUNT
  ) {
    return -1;
  }

  if (
    first.type === RewardType.DISCOUNT_AMOUNT &&
    [RewardType.COUPON, RewardType.COUPON_REWARD].includes(second.type!)
  ) {
    return 1;
  }

  // Then sort by date created
  if (isAfter(first.dateCreated!, second.dateCreated!)) {
    return -1;
  }

  if (isAfter(second.dateCreated!, first.dateCreated!)) {
    return 1;
  }

  return 0;
};
