import { ControllerFlowAPI, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import { initLoyaltyCouponNames } from '@wix/loyalty-coupon-names';
import { WIX_EVENTS, WIX_STORES, WIX_BOOKINGS } from '@wix/app-definition-ids';
import { loyaltyMyRewardsOpen } from '@wix/bi-logger-loyalty-uou/v2';

import { RewardOrigin } from '../../types/domain';
import { SettingsEvents, RewardsTabState } from '../../types/settings';
import {
  compareRewardsFn,
  createRewardDescriptionBuilder,
  toSimpleAccount,
  toSimpleRewards,
  createClaimedCouponTitleBuilder,
} from '../../utils';
import { createStore, getActionHandlers, Store } from './Widget/store';
import { ControllerProps } from './Widget';
import { RequestStatus } from '../../types/store';
import { rewardsSlice } from './Widget/store/slices';
import { createFixtures } from '../../utils/create-fixtures';
import { isRewardAvailable } from '../../utils/is-reward-available';
import { loadData } from '../../utils/load-data';
import { queryInstalledApps } from '../../api/installed-apps.api';

const getControllerProps = (store: Store) => {
  const state = store.getState();
  const actionHandlers = getActionHandlers(store);
  const props: Partial<ControllerProps> = {
    ...state,
    ...actionHandlers,
  };

  return props;
};

const showCoupons = async (flowAPI: ControllerFlowAPI) => {
  const {
    data: { appsMap },
  } = await flowAPI.httpClient.request(queryInstalledApps({ appDefIds: [WIX_STORES, WIX_EVENTS, WIX_BOOKINGS] }));

  if (appsMap[WIX_STORES] || appsMap[WIX_EVENTS] || appsMap[WIX_BOOKINGS]) {
    return true;
  }

  return false;
};

const createController: CreateControllerFn = async ({
  controllerConfig: { config, setProps, wixCodeApi },
  flowAPI,
}) => {
  const componentEventHandler = createEventHandler<SettingsEvents>(config.publicData.COMPONENT || {});
  const setWidgetProps = (props: Partial<ControllerProps>) => {
    setProps(props);
  };

  if (flowAPI.controllerConfig.platformAPIs.bi?.viewerName === 'thunderbolt') {
    flowAPI.controllerConfig.setProps({ fitToContentHeight: true });
  }

  return {
    async pageReady() {
      componentEventHandler.on('rewardsTabState', (rewardsTabState: RewardsTabState) =>
        setWidgetProps({
          rewardsTabState,
        }),
      );
      componentEventHandler.onReset(() =>
        setWidgetProps({
          rewardsTabState: RewardsTabState.Default,
        }),
      );
      try {
        await initLoyaltyCouponNames(flowAPI.translations.i18n);
        const isEditor = flowAPI.environment.isEditor;
        const isPreview = flowAPI.environment.isPreview;
        const { loyaltyProgram, account, rewards, templateCoupons, claimedCoupons, tiersProgram } =
          isEditor || isPreview ? await createFixtures(flowAPI, await showCoupons(flowAPI)) : await loadData(flowAPI);

        const store = createStore(
          {
            flowAPI,
            wixCodeApi,
          },
          {
            accountConfig: {
              fetchAccountStatus: RequestStatus.IDLE,
              account: toSimpleAccount(account),
              currency: wixCodeApi.site.currency ?? 'USD',
            },
            couponsConfig: {
              redeemCouponStatus: RequestStatus.IDLE,
              claimedCoupons,
              templateCoupons,
            },
            rewardsConfig: {
              simpleRewards: [],
              rawRewards: rewards,
              redeemRewardStatus: RequestStatus.IDLE,
            },
            loyaltyProgramConfig: {
              program: loyaltyProgram ?? {},
            },
            tiersProgramConfig: {
              programSettings: tiersProgram.programSettings ?? {},
              tiers: tiersProgram.tiers ?? [],
            },
          },
        );

        store.subscribe(() =>
          setWidgetProps({
            ...store.getState(),
          }),
        );

        const props = getControllerProps(store);

        setWidgetProps({
          ...props,
        });

        const locale = wixCodeApi.site.regionalSettings ?? 'en-US';
        const currency = wixCodeApi.site.currency ?? 'USD';

        const simpleRewards = toSimpleRewards(
          rewards,
          templateCoupons,
          claimedCoupons,
          flowAPI.translations,
          createRewardDescriptionBuilder(loyaltyProgram!, flowAPI, locale, currency),
          createClaimedCouponTitleBuilder(flowAPI, locale, currency),
        );

        flowAPI.bi?.updateDefaults({
          totalPoints: account.points?.balance,
          totalRewards: simpleRewards.filter((r) => r.rewardOrigin === RewardOrigin.USER).length,
          availableRewards: simpleRewards
            .filter((r) => r.rewardOrigin === RewardOrigin.USER)
            .filter((r) =>
              isRewardAvailable({ requiredPoints: r.requiredPoints, pointsBalance: account.points?.balance }),
            )
            .map((r) => r.id)
            .join(','),
          unavailableRewards: simpleRewards
            .filter((r) => r.rewardOrigin === RewardOrigin.USER)
            .filter(
              (r) => !isRewardAvailable({ requiredPoints: r.requiredPoints, pointsBalance: account.points?.balance }),
            )
            .map((r) => r.id)
            .join(','),
        });

        const sortedRewards = simpleRewards
          .slice()
          .sort((first, second) => compareRewardsFn(first, second, account.points?.balance));

        store.dispatch(rewardsSlice.actions.setSimpleRewards(sortedRewards));
      } catch (e) {
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
          console.error(e);
        }
        if (e instanceof Error) {
          flowAPI.reportError(e);
        }
        setProps({
          hasError: true,
        });
      }

      if (flowAPI.environment.isViewer) {
        flowAPI.bi?.report(loyaltyMyRewardsOpen({}));
      }
    },
    updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
    },
  };
};

export default createController;
