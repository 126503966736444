import { LoyaltyCoupon } from '@wix/ambassador-loyalty-v1-coupon/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getCouponSubtitle } from '@wix/loyalty-coupon-names';

export const createClaimedCouponTitleBuilder =
  ({ translations }: ControllerFlowAPI, locale: string, currency: string) =>
  (claimedCoupon: LoyaltyCoupon) => {
    const { t } = translations;

    return `${claimedCoupon.rewardName} - ${getCouponSubtitle({
      coupon: claimedCoupon.couponReference!,
      referenceCoupon: true,
      locale,
      currency,
      t,
    })}`;
  };
