import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loyaltyUouClick } from '@wix/bi-logger-loyalty-uou/v2';

import { ThunkApiConfig } from '../../../../../../types/store';

const navigateToLoyaltyPage = createAsyncThunk<void, void, ThunkApiConfig>(
  'navigation/navigateToLoyaltyPage',
  async (_, { extra, rejectWithValue }) => {
    const { flowAPI, wixCodeApi } = extra;
    try {
      if (flowAPI.environment.isViewer) {
        flowAPI.bi?.report(
          loyaltyUouClick({
            value: 'learn_how_to_earn_points',
          }),
        );
      }
      const url = await wixCodeApi.site.getSectionUrl({
        appDefinitionId: '553c79f3-5625-4f38-b14b-ef7c0d1e87df',
        sectionId: '86935446-7375-473b-8461-2be576b17182',
      });
      if (url.relativeUrl) {
        wixCodeApi.location.to?.(url.relativeUrl);
      }
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(navigateToLoyaltyPage.fulfilled, () => {}).addCase(navigateToLoyaltyPage.rejected, () => {});
  },
});

export const navigationThunk = { navigateToLoyaltyPage };
