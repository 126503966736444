import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ProgramStatus, LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { LoyaltyCoupon, Status, CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { getTiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/http';

import { TiersProgram } from '../../types/domain';
import { Experiments } from '../../constants';

export const createFixtures = async (
  { experiments, httpClient, translations }: ControllerFlowAPI,
  showCouponRewards: boolean,
) => {
  const { t } = translations;
  const tiersProgram: TiersProgram = {};
  const tiersEnabled = experiments.enabled(Experiments.ShowTiers);

  const { data: loyaltyProgramData } = await httpClient.request(getLoyaltyProgram({}));
  const loyaltyProgram: LoyaltyProgram = { ...loyaltyProgramData.loyaltyProgram, status: ProgramStatus.ACTIVE };

  const account: LoyaltyAccount = {
    points: {
      balance: 30,
    },
    tier: {
      points: 60,
    },
  };

  const rewards: Reward[] = [
    {
      name: t('app.fixtures.reward-a.title'),
      active: true,
      discountAmount: {
        configsByTier: [
          {
            costInPoints: 20,
            amount: '15',
          },
        ],
      },
      type: RewardType.DISCOUNT_AMOUNT,
    },
    {
      name: t('app.fixtures.reward-b.title'),
      active: true,
      discountAmount: {
        configsByTier: [
          {
            costInPoints: 50,
            amount: '7',
          },
        ],
      },
      type: RewardType.DISCOUNT_AMOUNT,
    },
  ];

  let templateCoupons: CouponReference[] = [];
  let claimedCoupons: LoyaltyCoupon[] = [];

  if (showCouponRewards) {
    const templateCouponCId = '0a79af45-c6ad-40b0-995b-76f9247b27a4';
    const templateCouponDId = '00c53f39-2185-4f01-adc7-925574e2ca70';
    const templateCouponEId = '64f840b7-2c4f-49be-8330-1e25cacc75bf';
    const claimedCouponId = '4e78b097-6c33-497f-b404-703e8bacbee5';

    rewards.push(
      {
        name: t('app.fixtures.reward-d.title'),
        active: true,
        coupon: {
          templateId: templateCouponDId,
          configs: [
            {
              requiredPoints: 20,
            },
          ],
        },
        type: RewardType.COUPON,
        createdDate: new Date(),
      },
      {
        name: t('app.fixtures.reward-e.title'),
        active: true,
        coupon: {
          templateId: templateCouponEId,
          configs: [
            {
              requiredPoints: 50,
            },
          ],
        },
        type: RewardType.COUPON,
        createdDate: new Date(),
      },
    );

    templateCoupons = [
      {
        couponId: templateCouponCId,
        specification: {
          moneyOffAmount: 3,
        },
      },
      {
        couponId: templateCouponDId,
        specification: {
          moneyOffAmount: 4,
        },
      },
    ];

    claimedCoupons = [
      {
        id: claimedCouponId,
        createdDate: new Date(),
        status: Status.ACTIVE,
        couponReference: {
          code: 'C00LC0D342',
          specification: {
            moneyOffAmount: 12,
          },
        },
        rewardName: t('app.fixtures.reward-c.title'),
      },
    ];
  }

  if (tiersEnabled) {
    const { data: tiersData } = await httpClient.request(getTiersProgramSettings({}));
    const tiersStatus = tiersData.programSettings?.status;

    tiersProgram.programSettings = {
      status: tiersStatus,
      baseTierDefinition: {
        name: t('app.fixtures.tier-basic.title'),
        icon: {
          url: 'shapes/11062b_0a8e17936d5b4d46971649be036c781b.svg',
        },
      },
    };

    tiersProgram.tiers = [
      {
        id: 'fa4d72b2-096f-4fc7-89c4-d26163a4674a',
        tierDefinition: {
          name: t('app.fixtures.tier-silver.title'),
          icon: {
            url: 'shapes/11062b_0a8e17936d5b4d46971649be036c781b.svg',
          },
        },
        requiredPoints: 100,
      },
    ];
  }

  return {
    loyaltyProgram,
    account,
    rewards,
    templateCoupons,
    claimedCoupons,
    tiersProgram,
  };
};
