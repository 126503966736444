import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { loyaltyUouApplyRewardClick } from '@wix/bi-logger-loyalty-uou/v2';

import { SimpleReward } from '../../../../../../types/domain';
import { RequestStatus, ThunkApiConfig } from '../../../../../../types/store';

export interface RewardsConfigProps {
  redeemRewardStatus: RequestStatus;
  rawRewards: Reward[];
  simpleRewards: SimpleReward[];
}

const initialState: RewardsConfigProps = {
  redeemRewardStatus: RequestStatus.IDLE,
  rawRewards: [],
  simpleRewards: [],
};

type AppHandler = (reward: Reward, wixCodeApi: any, flowAPI: any) => Promise<void>;
type AppHandlerMap = {
  [id: string]: AppHandler;
};

const restaurantsOrdersAppDefinitionId = '13c1402c-27f2-d4ab-7463-ee7c89e07578';
const appHandlers: AppHandlerMap = {
  [restaurantsOrdersAppDefinitionId]: async (reward, wixCodeApi) => {
    const url = await wixCodeApi.site.getSectionUrl({
      appDefinitionId: '13e8d036-5516-6104-b456-c8466db39542',
      sectionId: '96254d42-7323-40cb-a7cb-b7c242019728',
    });

    if (url.relativeUrl) {
      wixCodeApi.location.to?.(url.relativeUrl);
    } else {
      throw new Error('Could not find restaurants order page');
    }
  },
};

const redeemReward = createAsyncThunk<void, SimpleReward, ThunkApiConfig>(
  'rewards/redeemReward',
  async (reward, { extra, getState, rejectWithValue }) => {
    const {
      rewardsConfig: { rawRewards },
      accountConfig: { account },
    } = getState();
    const { flowAPI, wixCodeApi } = extra;
    const item = rawRewards?.find((r: Reward) => r.id === reward.id);
    if (item) {
      if (flowAPI.environment.isViewer && item.id) {
        const params = new URLSearchParams(wixCodeApi.location.url);
        flowAPI.bi?.report(
          loyaltyUouApplyRewardClick({
            rewardId: item.id,
            rewardName: item.name,
            rewardType: item.type,
            totalPoints: account.pointsBalance!,
            referralInfo: params.has('referralInfo') ? params.get('referralInfo') ?? '' : '',
          }),
        );
      }

      const handler = appHandlers[restaurantsOrdersAppDefinitionId];
      if (handler) {
        try {
          await handler(item, wixCodeApi, flowAPI);
        } catch (error) {
          if (error instanceof Error) {
            flowAPI.reportError(error);
          }
          rejectWithValue(error);
        }
      }
    }
  },
);

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    resetRedeemRewardStatus: (state) => {
      state.redeemRewardStatus = RequestStatus.IDLE;
    },
    setSimpleRewards: (state, action: PayloadAction<SimpleReward[]>) => {
      state.simpleRewards = action.payload;
    },
    addReward: (state, action: PayloadAction<SimpleReward>) => {
      state.simpleRewards.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(redeemReward.fulfilled, (state) => {
        state.redeemRewardStatus = RequestStatus.SUCCESS;
      })
      .addCase(redeemReward.pending, (state) => {
        state.redeemRewardStatus = RequestStatus.LOADING;
      })
      .addCase(redeemReward.rejected, (state) => {
        state.redeemRewardStatus = RequestStatus.FAILURE;
      });
  },
});

export const rewardsThunk = { redeemReward };
